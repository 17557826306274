<template>
  <v-row>
    <v-col cols="12" lg="6" md="4">
      <v-toolbar color="transparent" flat tile>
        <v-toolbar-title v-text="$t('invoices')" />
        <v-spacer />
      </v-toolbar>
    </v-col>
    <v-col cols="12" lg="6" md="8">
      <sheet class="fill-height" dense depressed light outlined>
        <v-row>
          <v-col cols="12" sm="6">
            <company-preview :company="obInvoice.get('company', company)" />
          </v-col>

          <branch-select
            :disabled="readOnly"
            :label="$t('branch')"
            :value="obInvoice.branch_id"
            :wrap-options="{ cols: 12, lg: 6 }"
            class="col-sm-6 col-12"
            select
            tag="div"
            @change="onSelectBranch"
          />
        </v-row>
      </sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import BranchSelect from "@/modules/companies/components/BranchSelect.vue";
import type { Branch } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { CompanyPreview, BranchSelect },
})
export default class InvoiceFormTop extends Mixins(InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;

  onSelectBranch(obData: Branch) {
    this.obInvoice.set("branch_id", obData.id);
  }
}
</script>
